<!-- 营养资讯 -->
<template>
  <div class="page-main">
    <header class="page-header">
      <van-icon name="arrow-left" size="20px" @click="handleGoBack" />
      <div class="header-content">营养资讯</div>
    </header>
    <section class="page-info">
      <div class="info-title">
        {{ title }}
      </div>
      <div class="info-creator">
        <div class="creator-name"> 
          原创:{{creator}}
        </div>
        <div class="creato-date"> 
          发表时间:{{upd_dt}}
        </div>
      </div>
      <div class="info-content" v-html="content" />
    </section>

  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import preach from "../../api/ofs/preach"

import wx from 'weixin-js-sdk'

export default {
  name: "nutritionInformation",
  components: {},
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    onMounted(() => {
      //获取传入参数
      const options = $router.currentRoute.value.query;
      console.log(options)
      getPreachInfo(options.preach_id)
    });


    const data = reactive({
      title: "",
      content: "",
      creator: "",
      upd_dt: ""
    });

    const getPreachInfo = id => {
      const params = {
        preach_id: id
      }
      preach.getPreachInfo(params).then(res => {
        data.title = res.data.title
        data.content = res.data.content
        data.creator = res.data.create_uname
        data.upd_dt = res.data.upd_dt
      })
    }
    const handleGoBack = () => {
      wx.miniProgram.getEnv(res => {
        if (res.miniprogram) {
          console.log("miniProgram");
          wx.miniProgram.navigateBack()
        } else {
          console.log("weChat");
        }
      })
      $router.go(-1)
    }

    return {
      ...toRefs(data),
      handleGoBack
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;
  .page-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    position: relative;
    .btn-left {
      position: fixed;
      left: 16px;
      background-color: #efeff4;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
    }
    .header-content {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      flex: 1;
      font-weight: 700;
    }
  }
  .page-info {
    background-color: #fff;
    min-height: 300px;
    margin: 20px;
    .info-title {
      width: 100%;
      text-align: center;
      font-size: 20px;
      margin: 20px 0;
    }
    .info-creator {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-content: center;
      height: 20px;
      font-size: 14px;
      color: #AAAAAA;
      line-height: 20px;
      margin: 20px 0;
    }
    .info-content {
      font-size: 14px;
      line-height: 16px;
    }
  }
}
</style>
