import api from '../index'

const preach = {
    // 获取宣教类型列表
    getPreachCate(data) {
        return api({
            url: 'v2/ofs/preach-cate',
            method: 'post',
            data: data
        })
    },
    // 获取宣教内容列表
    getPreachList(data) {
        return api({
            url: 'v2/ofs/preach-list',
            method: 'post',
            data: data
        })
    },
    // 获取宣教内容详情
    getPreachInfo(data) {
        return api({
            url: 'v2/ofs/preach-info',
            method: 'post',
            data: data
        })
    },

}
export default preach
