import axios from 'axios'
import qs from 'qs'
import router from '../router/index'
import { Toast } from 'vant'
// import { getSession,delSession } from "../utils/sessionUtils"
import { getStorage,delStorage } from "../utils/localStorageUtils"
import CommonData from "../utils/commonData"

/**
 * 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */
const tip = msg => {
    Toast({
        message: msg,
        duration: 1000,
        forbidClick: true
    })
}

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
    router.replace({
        path: '/index',
        query: {
            redirect: router.currentRoute.fullPath
        }
    })
}

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
    // 状态码判断
    switch (status) {
        // 401: 未登录状态，跳转登录页
        case 401:
        toLogin()
        break
        // 403 token过期
        // 清除token并跳转登录页
        case 403:
        tip('登录过期，请重新登录')
        delStorage(CommonData.KeyStorages.KEY_USER_TOKEN)
        //   store.commit('loginSuccess', null);
        setTimeout(() => {
            toLogin()
        }, 1000)
        break
        // 404请求不存在
        case 404:
        tip('请求的资源不存在')
        break
        default:
        console.log(other)
    }
}

// 创建axios实例
const api = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 10000,
    responseType: 'json'
})

// 设置post请求头
api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

api.interceptors.request.use(
    request => {
        request.data['open_id'] = localStorage.getItem(CommonData.KeyStorages.KEY_USER_OPEN_ID)
        if (getStorage(CommonData.KeyStorages.KEY_IS_LOGIN)) {
            // body携带token、uid
            request.data['token'] = localStorage.getItem(CommonData.KeyStorages.KEY_USER_TOKEN)
        }
        // 是否将 POST 请求参数进行字符串化处理
        if (request.method === 'post') {
            request.data = qs.stringify(request.data, {
                arrayFormat: 'brackets'
            })
        }
        return request
    }
)

api.interceptors.response.use(
    response => {
        /**
         * 全局拦截请求发送后返回的数据，如果数据有报错则在这做全局的错误提示
         * 假设返回数据格式为：{ status: 1, error: '', data: '' }
         * 规则是当 status 为 1 时表示请求成功，为 0 时表示接口需要登录或者登录状态失效，需要重新登录
         * 请求出错时 error 会返回错误信息
         */
        const data = response.data
        //console.log(response.config.url + '=====返回数据' + JSON.stringify(data))
        if (data.code === 0) {
            return Promise.resolve(data)
        // } else if (data.code === 10003) {
        //     router.replace({
        //         path: '/ofsLogin',
        //         query: {
        //             redirect: router.currentRoute.fullPath
        //         }
        //     })
        } else {
            tip(data.message)
            return Promise.reject(data)
        }
    },
    error => {
        tip(error.message)
        return Promise.reject(error)
    }
)

export default api
