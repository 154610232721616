// 存储Storage

export const setStorage = (key, val) => {
  return localStorage.setItem(key, val)
}

export const getStorage = (key) => {
  return localStorage.getItem(key)
}

export const delStorage = (key) => {
  return localStorage.removeItem(key)
}

export const clearAllStorage = () => {
  return localStorage.clear()
}
